import { useState, useEffect } from "react";
import useSWR from "swr";
import Api from "../../services/Api";
import { createQueryParam } from "../../utils/components";

const limit = 10;

function useGetCustomerAddresses() {
  const [customer, setCustomer] = useState();
  useEffect(() => {
    setCustomer(JSON.parse(sessionStorage.getItem("customerId")));
  }, []);

  const getKey = () => {
    return `${
      process.env.NETLIFY_API_BASE_URL
    }?endpoint=customers/addresses?customer_id:in=${
      customer.response.customer_id
    }${createQueryParam("limit", limit)}`;
  };

  const { data, error, mutate: mutateAddresses } = useSWR(
    () => getKey(),
    getAddresses
  );

  const isLoading = !data && !error;
  const addresses = data ? data : [];

  return {
    addresses,
    isLoading,
    mutateAddresses,
  };
}

async function getAddresses(url) {
  const response = await Api.get(url);
  return response.data.data;
}

export default useGetCustomerAddresses;

import React from "react";
import Layout from "../layout/Layout";
import AccouintNav from "../components/account/account-nav";
import useGetCustomerAddresses from "../hooks/customer/useGetCustomerAddresses";
import { formatName } from "../utils/components";
import useDeleteCustomerAddress from "../hooks/customer/useDeleteCustomerAddress";
import IconPlus from "../assets/icon_js/IconPlus";

import { navigate } from "gatsby";
import BreadCrumbs from "../components/common/breadcrumbs/BreadCrumbs";

function formatAddres(addresses) {
  return (
    addresses &&
    addresses.map((add) => ({
      id: add.id,
      first_name: add.first_name,
      last_name: add.last_name,
      company: add.company,
      address1: add.address1,
      address2: add.address2,
      city: add.city,
      state_or_province: add.state_or_province,
      country: add.country,
      phone: add.phone,
      postal_code: add.postal_code,
    }))
  );
}

const Addresses = ({ location }) => {
  const { addresses, mutateAddresses } = useGetCustomerAddresses();

  const data = formatAddres(addresses);
  const { deleteCustomerAddress } = useDeleteCustomerAddress(mutateAddresses);

  const handleDelete = (id) => {
    if (
      id &&
      window.confirm("Are you sure you want to delete this address ?")
    ) {
      deleteCustomerAddress(id);
    }
  };

  return (
    <Layout>
      <div className="page-wrapper">
        <div className="page-heading-section text-center page-block">
          <BreadCrumbs location={location} />

          <h1 className="page-title">Addresses</h1>
        </div>
      </div>
      <div className="container">
        <AccouintNav path={"addresses"} />

        <div className="account-content">
          <ul className="row flex flex-wrap address-list">
            {data &&
              data.map((address) => {
                const {
                  id,
                  city,
                  state_or_province,
                  first_name,
                  last_name,
                  company,
                  address1,
                  address2,
                  country,
                  phone,
                } = address;
                return (
                  <li className="col address-item" key={id}>
                    <div className="adddress-item-inner">
                      <h6 className="address-title">
                        {formatName(first_name, last_name)}
                      </h6>
                      <ul className="address-detail">
                        <li>{company}</li>
                        <li>{address1}</li>
                        <li>{address2}</li>
                        <li>{`${city}, ${state_or_province}`}</li>
                        <li>{country}</li>
                        <li>
                          <span>Phone: </span>
                          {phone}
                        </li>
                      </ul>
                      <div className="form-actions">
                        <button
                          type="submit"
                          className="button button-small"
                          onClick={() =>
                            navigate("create-update-address", {
                              state: address,
                            })
                          }
                        >
                          Edit
                        </button>
                        <button
                          type="submit"
                          className="button button--secondary button-small"
                          onClick={() => handleDelete(id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            <li className="col address-item add-address-link">
              <div
                className="adddress-item-inner"
                onClick={() => navigate("/create-update-address")}
              >
                <i className="add-icon">
                  <IconPlus />
                </i>
                <p>New Address</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Addresses;

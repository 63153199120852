import { useState } from "react";
import Api from "../../services/Api";

function useDeleteCustomerAddress(mutateAddresses) {
  const [isLoading, setLoading] = useState(false);

  const deleteCustomerAddress = async (address_id) => {
    try {
      setLoading(true);
      await deleteAddress(
        `${
          process.env.NETLIFY_API_BASE_URL
        }?endpoint=customers/addresses?id:in=${address_id}`
      );
      mutateAddresses();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return {
    deleteCustomerAddress,
    isLoading,
  };
}

async function deleteAddress(url) {
  const response = await Api.delete(url);
  return response.data.data;
}

export default useDeleteCustomerAddress;

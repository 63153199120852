import React, { Component } from 'react'

export default class IconPlus extends Component {
    render() {
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="400" height="512" viewBox="0 0 400 512">
                <title></title>
                <g id="icomoon-ignore">
                </g>
                <path fill="none" strokeLinejoin="miter" strokeLinecap="butt" strokeMiterlimit="10" strokeWidth="40.96" stroke="#000" d="M36.16 256h327.68"></path>
                <path fill="none" strokeLinejoin="miter" strokeLinecap="butt" strokeMiterlimit="10" strokeWidth="40.96" stroke="#000" d="M200 92.16v327.68"></path>
            </svg>

        )
    }
}